// in src/users.js
import React, { FC } from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ShowButton,
  ArrayField,
  SingleFieldList,
  ChipField,
  UrlField,
} from 'react-admin';

export const AuditList: FC = (props) => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField aria-label="Audit id" label="ID" source="id" />
      <UrlField aria-label="Domain url" label="URL" source="url" />
      <TextField aria-label="Page name" label="Page Name" source="page_name" />
      <ArrayField aria-label="Tags associated with the issue" label="Audit tags" source="axe_data">
        <SingleFieldList>
          <ChipField source="id" />
        </SingleFieldList>
      </ArrayField>
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
);
