import React, { FC } from 'react';
import { fetchUtils, Admin as ReactAdmin, Resource } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';
import authProvider from './authProvider';


import { UserList, UserEdit, UserCreate } from './Users';
import { AuditList, AuditEdit, AuditCreate, AuditShow } from './Audits';
import { BACKEND_HOST } from '../config';
const httpClient = (url: any, options: any) => {
  if (!options) {
    options = {};
  }
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = simpleRestProvider(BACKEND_HOST + '/v1', httpClient);

export const Admin: FC = () => {
  return (
    <ReactAdmin
      role="menu"
      dataProvider={dataProvider} authProvider={authProvider}>
      {(permissions: 'admin' | 'user') => [
        permissions === 'admin' ? (
          <Resource
            aria-label="The users page"
            role="menuitem"
            name="users"
            list={UserList}
            edit={UserEdit}
            create={UserCreate}
          />
        ) : null,
        <Resource
          aria-label="The audits page"
          role="menuitem"
          name="audits"
          list={AuditList}
          edit={AuditEdit}
          create={AuditCreate}
          show={AuditShow}
        />
      ]}
    </ReactAdmin>
  );
};
