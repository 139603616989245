import { BACKEND_HOST } from '../config';

export const getMessage = async () => {
  const request = new Request(BACKEND_HOST+'/v1');
  const response = await fetch(request);

  const data = await response.json();

  if (data.message) {
    return data.message;
  }

  return Promise.reject('Failed to get message from backend');
};
