import React, { FC, useState, useEffect, useRef } from 'react';
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';
import { useDataProvider } from 'react-admin';
import './AuditEdit.css';
import NodeSubComponent from "./NodeSubComponent";

interface Node {
  html: string;
  target: string;
  failureSummary: string;
}

interface AuditData {
  url: string;
  page_name: string;
  id: string;
  axe_data: {
    id: string;
    impact: string;
    tags: string;
    description: string;
    help: string;
    helpUrl: string;
    nodes: Node[];
  }[];
}

type Props = {
  resource: string;
}

export const AuditCreate: FC<Props> = ({ resource }) => {
  const [jsonData, setJsonData] = useState<AuditData>({
    url: '',
    page_name: '',
    id: '',
    axe_data: [],
  });

  const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>({});
  const [mainTableValues, setMainTableValues] = useState<any>({});
  const [subTableValues, setSubTableValues] = useState<any>({});
  const loaded = Boolean(jsonData);

  const dataProvider = useDataProvider();
  useEffect(() => {
    setJsonData({
      url: '',
      page_name: '',
      id: '',
      axe_data: [],
    });
  }, []);

  const create = async (data: AuditData) => {
    try {
      await dataProvider.create(resource, { data });
      console.log('Create successful');
    } catch (error) {
      console.error('Create failed:', error);
    }
  };

  const handleCreate = () => {
    create(jsonData);
  }
  const handleSubTableBlur = (
    rowIndex: number,
    columnIndex: number,
    column: string,
    value: string
  ) => {
    const updatedData = { ...jsonData };
    updatedData.axe_data[rowIndex].nodes[columnIndex][column as keyof Node] = value as any;
    setJsonData(updatedData);
  };


  const handleMainTableBlur = (index: number, column: string, value: string) => {
    const updatedData = { ...jsonData };
    updatedData.axe_data[index][column as keyof typeof jsonData.axe_data[typeof index]] = value as any;
    setJsonData(updatedData);
  };


  const handleEdit = (column: string, value: string) => {
    const updatedData = { ...jsonData };
    updatedData[column as keyof AuditData] = value as any;
    setJsonData(updatedData);
  }

  const handleNodeEdit = (axeDataIndex: number, nodeIndex: number, column: string, value: string) => {
    const updatedData = { ...jsonData };
    const updatedExpandedRows = { ...expandedRows };
    updatedExpandedRows[axeDataIndex] = true;
    setExpandedRows(updatedExpandedRows);
    const nodes = [...updatedData.axe_data[axeDataIndex].nodes];
    nodes[nodeIndex][column as keyof Node] = value as any;
    const updatedNodes = [
      ...nodes.slice(0, nodeIndex),
      nodes[nodeIndex],
      ...nodes.slice(nodeIndex + 1),
    ];
    updatedData.axe_data[axeDataIndex].nodes = updatedNodes;
    setJsonData(updatedData);
  };


  const handleAddAxeData = (index: number) => {
    setJsonData({
      ...jsonData,
      axe_data: [
        ...jsonData.axe_data.slice(0, index + 1),
        {
          id: '',
          impact: '',
          tags: '',
          description: '',
          help: '',
          helpUrl: '',
          nodes: [],
        },
        ...jsonData.axe_data.slice(index + 1),
      ],
    });
  };


  const handleRemoveAxeData = (index: number) => {
    setJsonData({
      ...jsonData,
      axe_data: jsonData.axe_data.filter((_, i) => i !== index),
    });
  };

  const handleAddNode = (axeDataIndex: number) => {
    const updatedData = { ...jsonData };
    const updatedExpandedRows = { ...expandedRows };
    updatedExpandedRows[axeDataIndex] = true;
    setExpandedRows(updatedExpandedRows);
    const updatedNodes = [
      ...updatedData.axe_data[axeDataIndex].nodes,
      {
        html: '',
        target: '',
        failureSummary: '',
      },
    ];
    updatedData.axe_data[axeDataIndex].nodes = updatedNodes;
    setJsonData(updatedData);
  };


  const handleRemoveNode = (axeDataIndex: number, nodeIndex: number) => {
    const updatedData = { ...jsonData };
    const updatedExpandedRows = { ...expandedRows };
    updatedExpandedRows[axeDataIndex] = true;
    setExpandedRows(updatedExpandedRows);

    // Check if the object exists before accessing its properties
    if (updatedData.axe_data && updatedData.axe_data[axeDataIndex]) {
      updatedData.axe_data[axeDataIndex].nodes = updatedData.axe_data[axeDataIndex].nodes.filter(
        (_, i) => i !== nodeIndex
      );
      setJsonData(updatedData);
    } else {
      console.error("Cannot find object at axeDataIndex:", axeDataIndex);
    }
  };



  const columns = [
    {
      Header: 'Audit data',
      columns: [
        {
          Header: 'ID',
          accessor: 'id',
          sortable: true,
          Cell: (props: any) => (
            <input
              type="text"
              defaultValue={mainTableValues[`${props.index}-id`] || props.value}
              onBlur={(e) => handleMainTableBlur(props.index, 'id', e.target.value)}
              readOnly={false}
            />
          )
        },
        {
          Header: 'Impact',
          accessor: 'impact',
          sortable: true,
          Cell: (props: any) => (
            <input
              type="text"
              defaultValue={mainTableValues[`${props.index}-impact`] || props.value}
              onBlur={(e) => handleMainTableBlur(props.index, 'impact', e.target.value)}
              readOnly={false}
            />
          )
        },
        {
          Header: 'Tags',
          accessor: 'tags',
          sortable: true,
          Cell: (props: any) => (
            <input
              type="text"
              defaultValue={mainTableValues[`${props.index}-tags`] || props.value}
              onBlur={(e) => handleMainTableBlur(props.index, 'tags', e.target.value)}
              readOnly={false}
            />
          )
        },
        {
          Header: 'Description',
          accessor: 'description',
          sortable: true,
          Cell: (props: any) => (
            <textarea
              defaultValue={mainTableValues[`${props.index}-description`] || props.value}
              onBlur={(e) => handleMainTableBlur(props.index, 'description', e.target.value)}
              readOnly={false}
            ></textarea>
          )
        },
        {
          Header: 'Help',
          accessor: 'help',
          sortable: true,
          Cell: (props: any) => (
            <textarea
              defaultValue={mainTableValues[`${props.index}-help`] || props.value}
              onBlur={(e) => handleMainTableBlur(props.index, 'help', e.target.value)}
              readOnly={false}
            ></textarea>
          )
        },
        {
          Header: 'Help URL',
          accessor: 'helpUrl',
          sortable: true,
          Cell: (props: any) => (
            <input
              type="text"
              defaultValue={mainTableValues[`${props.index}-helpUrl`] || props.value}
              onBlur={(e) => handleMainTableBlur(props.index, 'helpUrl', e.target.value)}
              readOnly={false}
            />
          )
        },
        // {
        //   Header: 'Nodes',
        //   accessor: 'nodes',
        //   Cell: (props: any) => {
        //     const nodes = props.original?.nodes || [];
        //     const [isDropdownVisible, setIsDropdownVisible] = useState(false);
        //     const toggleDropdown = () => setIsDropdownVisible(!isDropdownVisible);
        //     const isLastRow = props.index === jsonData.axe_data.length - 1;

        //     return (
        //       <div className="nodes-container">
        //         <button className="toggle-nodes" onClick={toggleDropdown}>
        //           {isDropdownVisible ? 'Hide Nodes' : 'Show Nodes'}
        //         </button>
        //       </div>
        //     );
        //   },
        // },
        {
          Header: 'Actions',
          accessor: 'actions',
          sortable: true,
          Cell: (props: any) => (
            <div>
              <button onClick={() => handleAddAxeData(props.index)}>
                Add Row
              </button>
              <button onClick={() => handleRemoveAxeData(props.index)}>
                Remove Row
              </button>
            </div>
          ),
        },
      ]
    },
  ];

  if (!loaded) {
    return <div>Loading...</div>;
  }

  const nodeColumns = [
    {
      Header: 'HTML Tag',
      accessor: 'html',
      sortable: true,
      Cell: (props: any) => (
        <input
          type="text"
          defaultValue={
            subTableValues[`${props.row._index}-${props.index}-html`] || props.value
          }
          onBlur={(e) =>
            handleSubTableBlur(
              props.row._index,
              props.index,
              'html',
              e.target.value
            )
          }
          readOnly={false}
        />
      )
    },
    {
      Header: 'Element ID',
      accessor: 'target',
      sortable: true,
      Cell: (props: any) => (
        <input
          type="text"
          defaultValue={
            subTableValues[`${props.row._index}-${props.index}-target`] || props.value
          }
          onBlur={(e) =>
            handleSubTableBlur(
              props.row._index,
              props.index,
              'target',
              e.target.value
            )
          }
          readOnly={false}
        />
      )
    },
    {
      Header: 'Failure Summary',
      accessor: 'failureSummary',
      sortable: true,
      Cell: (props: any) => (
        <textarea
          defaultValue={
            subTableValues[`${props.row._index}-${props.index}-failureSummary`] || props.value
          }
          onBlur={(e) =>
            handleSubTableBlur(
              props.row._index,
              props.index,
              'failureSummary',
              e.target.value
            )
          }
          readOnly={false}
        ></textarea>
      )
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      sortable: true,
      Cell: (props: any) => (
        <div>
          <button onClick={() => handleAddNode(props.row._index)}>
            Add Row
          </button>
          <button onClick={() => handleRemoveNode(props.row._index, props.index)}>
            Remove Row
          </button>
        </div>
      ),
    },
  ]

  const isEmptyTable = jsonData?.axe_data.length === 0;
  return (
    <>
      <div>
        <label>
          URL:
          <input
            type="text"
            value={jsonData.url}
            onChange={(e) => handleEdit('url', e.target.value)}
          />
        </label>
      </div>
      <div>
        <label>
          Page name:
          <input
            type="text"
            value={jsonData.page_name}
            onChange={(e) => handleEdit('page_name', e.target.value)}
          />
        </label>
      </div>
      <div>
        <label>
          Audit ID:
          <input
            type="text"
            value={jsonData.id}
            onChange={(e) => handleEdit('id', e.target.value)}
          />
        </label>
      </div>
      {jsonData.axe_data.length === 0 && (
        <button onClick={() => handleAddAxeData(0)}>Add Row</button>
      )}
      <ReactTable
        data={jsonData?.axe_data || []}
        columns={columns}
        showPagination={true}
        minRows={5}
        defaultPageSize={5}
        filterable
        expanded={expandedRows}
        onExpandedChange={(newExpanded: any) => setExpandedRows(newExpanded)}
        SubComponent={(row) => {
          return (
            <NodeSubComponent
              key={`subcomponent-${row.index}`}
              row={row}
              nodeColumns={nodeColumns}
              handleAddNode={handleAddNode}
            />
          );
        }}
      />
      {isEmptyTable && (
        <div className="overlay">
          <button
            className="overlay-button"
            onClick={() => handleAddAxeData(jsonData?.axe_data.length || 0)}
          >
            Add Row
          </button>
        </div>
      )}
      <button onClick={handleCreate}>Create Audit</button>
    </>
  );
}

