// in src/users.js
import React, { FC } from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  Datagrid,
  ArrayField,
  UrlField,
  FunctionField
} from 'react-admin';

const PostShow = () => (
  <SimpleShowLayout>
    <ArrayField label="Data points" source="nodes">
      <Datagrid tabIndex="0" role="row">
        <TextField aria-label="The html tag where the issue lies" label="Html element" source="html" />
        <TextField aria-label="The html element path where it element is" label="Element ID" source="target" />
        <TextField aria-label="The failure summary for the individual tag" label="Failure Summary" source="failureSummary" />
      </Datagrid>
    </ArrayField>
  </SimpleShowLayout>
);

export const AuditShow: FC = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <UrlField  aria-label="Domain url" label="URL" source="url" />
      <TextField aria-label="Page name" label="Page name" source="page_name" />
      <TextField aria-label="Audit id" label="Audit ID" source="id" />
      <ArrayField source="axe_data">
        <Datagrid expand={<PostShow />} expandarialabel="Expand for more details" tabIndex="0" role="row">
          <TextField aria-label="Issue ID" label="ID" source="id" />
          <TextField aria-label="Impact of the issue" label="Impact" source="impact" />
          <FunctionField
            aria-label="Tags associated with the issue"
            label="Tags"
            render={(record:any) => {
              return Array.isArray(record.tags)
              ? record.tags.map((tag: string) => tag).join(', ')
              : '';
            }}
          />
          <TextField aria-label="Issue description" label="Description" source="description" />
          <TextField aria-label="Issue help" label="Help" source="help" />
          <TextField aria-label="Help reference URL" label="Help URL" source="helpUrl" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);
