import React, { FC, memo } from "react";
import ReactTable from 'react-table-6';

interface NodeSubComponentProps {
  row: any;
  nodeColumns: any;
  handleAddNode: (index: number) => void;
}

const NodeSubComponent: FC<NodeSubComponentProps> = memo(({ row, nodeColumns, handleAddNode }) => {
      
  const isEmptyNodeTable = row.original.nodes.length === 0;
  return (
    <div style={{ padding: "20px" }}>
      <ReactTable
        data={row.original.nodes}
        columns={nodeColumns}
        showPagination={true}
        defaultPageSize={5}
        minRows={5}
        filterable
      />
      {isEmptyNodeTable && (
        <div className="overlay">
          <button
            className="overlay-button"
            onClick={() => handleAddNode(row.index)}
          >
            Add Node
          </button>
        </div>
      )}
    </div>
  );
});

export default NodeSubComponent;
